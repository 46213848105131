import { useMutation, useQuery } from '@apollo/client';
import { AlignmentY, EmotionColors } from '@lmc-eu/spirit-web-react';
import { Toast } from '@lmc-eu/spirit-web-react/components';
import { DEFAULT_TOAST_COLOR } from '@lmc-eu/spirit-web-react/components/Toast/constants';
import React, { useEffect, useState } from 'react';
import HtmlParser from '@components/utilities/HtmlParser';
import { FlashMessage, FlashMessagesQuery, FlashMessagesQueryVariables } from 'graphql/generated/types/graphqlTypes';
import { CLEAR_FLASH_MESSAGES } from 'graphql/mutations/clearFlashMessages';
import { FLASH_MESSAGES } from 'graphql/queries/flashMessage';
import FlashMessageBar from './FlashMessage/FlashMessageBar';

const FlashMessages = () => {
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([]);
  const [clearFlashMessages] = useMutation(CLEAR_FLASH_MESSAGES, {
    refetchQueries: [FLASH_MESSAGES],
  });
  const { data } = useQuery<FlashMessagesQuery, FlashMessagesQueryVariables>(FLASH_MESSAGES);

  useEffect(() => {
    if (data?.flashMessages && data.flashMessages.length > 0) {
      setFlashMessages(data.flashMessages);
      clearFlashMessages();
    }
  }, [data?.flashMessages]);

  const getFlashMessageColorByType = (type: string) => {
    switch (type) {
      case 'success':
        return EmotionColors.SUCCESS;
      case 'error':
        return EmotionColors.DANGER;
      case 'warning':
        return EmotionColors.WARNING;
      case 'notice':
        return EmotionColors.INFORMATIVE;
      default:
        return DEFAULT_TOAST_COLOR;
    }
  };

  if (flashMessages.length === 0) return null;

  return (
    <div>
      <Toast alignmentY={AlignmentY.TOP}>
        {flashMessages.map((flashMessage, index) => (
          <FlashMessageBar
            key={index}
            id={`flash-message-${index}`}
            color={getFlashMessageColorByType(flashMessage.type)}
          >
            <HtmlParser htmlString={flashMessage.message} />
          </FlashMessageBar>
        ))}
      </Toast>
    </div>
  );
};

export default FlashMessages;
